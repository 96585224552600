import React, { useState } from 'react'
import styles from './Header.module.scss'

import { IconButton } from '@mui/material'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import MenuOpenTwoToneIcon from '@mui/icons-material/MenuOpenTwoTone'

import GlobalDrawer, { toggleGlobalDrawer } from '../Drawer/GlobalDrawer'
import CustomSearchBox from '../CustomSearchBox'

interface PROPS {
  currentRefinement: string
  isSearchStalled: boolean
  refine: Function
}

const Header: React.FC<PROPS> = ({currentRefinement, isSearchStalled, refine }) => {
  const [ isOpenGlobalDrawer, setIsOpenGlobalDrawer] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  return (
    <>
      <header className={styles.header}>
        <div className={styles.header__container}>
          <div className={styles.header__inner}>
            <div className={styles.header__col_l}>
              <h1>Tweet<span>Collection</span></h1>
            </div>

            <div className={styles.header__col_r}>
              <div className={styles.header__searchbox}>
                <CustomSearchBox
                  currentRefinement={currentRefinement}
                  isSearchStalled={isSearchStalled}
                  refine={refine}
                />
              </div>
              <nav className={styles.header__nav}>
                <ul>
                  <li>
                    <IconButton onClick={() => console.log("click1")}>
                      <AutorenewRoundedIcon className={styles.header__nav__icon} />
                    </IconButton>
                  </li>
                  <li>
                    <IconButton onClick={toggleGlobalDrawer(isOpenGlobalDrawer, setIsOpenGlobalDrawer, true)}>
                      <MenuOpenTwoToneIcon/>
                    </IconButton>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <GlobalDrawer
        isOpenDrawer={isOpenGlobalDrawer}
        setIsOpenDrawer={setIsOpenGlobalDrawer}
      />
    </>
  )
}

export default Header