import React from 'react'

import styles from './Progress.module.scss'

import { connectStateResults } from 'react-instantsearch-dom'
import { useSelector } from 'react-redux'
import { selectRefresh } from '../../features/refreshIndexSlice'
import CircularProgress from '@mui/material/CircularProgress'
import { Backdrop, Box, Modal } from '@mui/material'

const Progress = connectStateResults(() => {
  const refresh = useSelector(selectRefresh)

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={refresh}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.progress}>
        <Box>
          <CircularProgress
            sx={{
              color: '#',
            }}
          />
        </Box>
      </div>
    </Modal>
  )
})
export default Progress