import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectUser } from '../../features/userSlice'
import { auth } from '../../firebase'
import styles from './GlobalDrawer.module.scss'

import { Avatar } from '@material-ui/core'
import { Drawer, List, ListItem, ListItemButton } from '@mui/material'
import Logout from '@mui/icons-material/Logout'

interface PROPS {
  isOpenDrawer: { [name: string]: boolean }
  setIsOpenDrawer: any
}

export const toggleGlobalDrawer =
    (state: Object, setState: any, isOpen: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }

      setState({ ...state, 'right': isOpen })
    }

const GlobalDrawer: React.FC<PROPS> = ({ isOpenDrawer, setIsOpenDrawer }) => {
  const user = useSelector(selectUser)
  const handleOpenContact = () => {
    window.open("https://docs.google.com/forms/d/e/1FAIpQLScNXoxKDbu5xKm9uG3mumkAan9ZWdbyY3bIjvokDu_32XzpFg/viewform")
  }

  return (
    <>
      <Drawer
        anchor='right'
        open={isOpenDrawer['right']}
        onClose={toggleGlobalDrawer(isOpenDrawer, setIsOpenDrawer, false)}
        PaperProps={{
          sx: {
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }
        }}
      >
        <div className={styles.global_drawer__inner}>
          <div  className={styles.global_drawer__userCard}>
            <div className={styles.global_drawer__userCard__user}>
              <Avatar src={user.photoUrl} />
              <p className={styles.global_drawer__userCard__name}>{user.displayName}</p>
            </div>
          </div>
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={() => auth.signOut()}>
                <Logout fontSize="small" />
                <p className={styles.global_drawer__txt}>Logout</p>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <Link to="/terms" className={styles.global_drawer__link}>
                  <p className={styles.global_drawer__txt_small}>Terms of service</p>
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <Link to="/privacy" className={styles.global_drawer__link}>
                  <p className={styles.global_drawer__txt_small}>Privacy policy</p>
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleOpenContact()}>
                <p className={styles.global_drawer__txt_small}>Contact</p>
              </ListItemButton>
            </ListItem>
          </List>
        </div>
      </Drawer>
    </>
  )
}

export default GlobalDrawer