import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../app/store'

export const refreshIndexSlice = createSlice({
  name: 'refreshIndex',
  initialState: {
    refresh: false,
  },
  reducers: {
    refreshIndex: (state) => {
      state.refresh = true
    },
    completeRefresh: (state) => {
      state.refresh = false
    },
  },
});

export const { refreshIndex, completeRefresh } = refreshIndexSlice.actions

export const selectRefresh = (state: RootState) => state.refresh.refresh

export default refreshIndexSlice.reducer
