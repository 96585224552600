import React from 'react'
import styles from './CustomSearchBox.module.scss'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

interface PROPS {
  currentRefinement: string
  isSearchStalled: boolean
  refine: Function
}

const CustomSearchBox:React.FC<PROPS> = ({ currentRefinement, refine }) => {
  return (
    <div className={styles.searchbox}>
      <input
        type="search"
        value={currentRefinement}
        placeholder="Search..."
        onChange={event => refine(event.currentTarget.value)}
      />
      <IconButton
        className={styles.searchbox__closeButton}
        onClick={() =>  refine('')}
      >
        <CloseIcon className={styles.searchbox__closeButton__icon} />
      </IconButton>
    </div>
  )
}

export default CustomSearchBox