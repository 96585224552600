import React, { useEffect, useState } from 'react'
import styles from "./CustomHit.module.scss"
import { useSelector } from 'react-redux'
import { selectRefresh } from '../features/refreshIndexSlice'

import { Highlight } from 'react-instantsearch-dom'
import { Avatar } from '@material-ui/core'
import { IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'

import EditTagModal from "./Modal/EditTagModal"

import { Bookmark } from "../types/bookmark"

interface PROP {
  hit: Bookmark
}

const CustomHit: React.FC<PROP> = ({ hit }) => {
  const savedDate = new Date(parseInt(hit.savedAt, 10))
  const savedYear = savedDate.getFullYear()
  const savedMonth = savedDate.getMonth() + 1
  const savedDay = savedDate.getDate()

  const createdDate = new Date(hit.tweetedAt)
  const createdYear = createdDate.getFullYear()
  const createdMonth = createdDate.getMonth() + 1
  const createdDay = createdDate.getDate()

  const [openModal, setOpenModal] = useState(false)
  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)
  const [hashTags, setHashTags] = useState([...hit.hashTags])

  const refresh = useSelector(selectRefresh)

  useEffect(() => {
    setHashTags(hit.hashTags)
  }, [hit.hashTags, refresh])

  return (
    <li className={styles.bookmark}>
      <div className={styles.bookmark__container}>
        <div className={styles.bookmark__head}>
          <a
            href={`https://twitter.com/${hit.poster.screenName}`}
            rel="noopener noreferrer"
            target="_blank"
            className={styles.bookmark__head__user}
          >
            <Avatar src={hit.poster.profileImage} className={styles.bookmark__head__user_avatar} />
            <span className={styles.bookmark__head__user_name}>{hit.poster.name} @{hit.poster.screenName}</span>
          </a>
          <p className={styles.bookmark__head__savedAt}>{`${savedDay}/${savedMonth}/${savedYear}`}</p>
        </div>

        <div className={styles.bookmark__body}>
          <Highlight attribute="text" hit={hit} tagName="mark" className={styles.bookmark__body_txt} />

          <div className={styles.bookmark__body__meta}>
            <p className={styles.bookmark__body__meta_postedAt}>
              Tweeted at:&nbsp
              <a
                href={`https://twitter.com/${hit.poster.screenName}/status/${hit.tweetId}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {`${createdDay}/${createdMonth}/${createdYear}`}
              </a>
            </p>
            <div className={styles.bookmark__body__meta__tags}>
              <p className={styles.bookmark__body__meta__tags_head}>Hashtag:</p>
              <ul className={styles.bookmark__body__meta__tags_list}>
                {
                  hashTags.map((tag: string) => (
                    <li
                      className={styles.bookmark__body__meta__tags_list__item}
                      key={tag}
                    >
                      {tag}
                    </li>
                  ))
                }
              </ul>
              <IconButton
                className={styles.bookmark__body__meta__tags_iconButton}
                onClick={() => handleOpenModal()}
              >
                <EditIcon className={styles.bookmark__body__meta__tags_iconButton__icon} />
              </IconButton>
              <EditTagModal
                objectID={hit.objectID}
                hashTags={hit.hashTags}
                openModal={openModal}
                handleCloseModal={handleCloseModal}
              />
            </div>
          </div>
        </div>
      </div>
    </li>
  )
}

export default CustomHit