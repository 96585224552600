import React, { useEffect, useState } from "react"
import styles from './EditTagModal.module.scss'
import { db } from '../../firebase'
import { IconButton, Backdrop, Box, Chip, Modal, Fade, Stack, Snackbar, SnackbarOrigin } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from '../../features/userSlice'
import { refreshIndex } from '../../features/refreshIndexSlice'
import { selectRefresh } from '../../features/refreshIndexSlice'

interface PROPS {
  objectID: string,
  hashTags: Array<string>,
  openModal: boolean,
  handleCloseModal: any,
}

const EditTagModal: React.FC<PROPS> = (props) => {
  const user = useSelector(selectUser)
  const refresh = useSelector(selectRefresh)
  const dispatch = useDispatch()
  const draft = [...props.hashTags]
  const [draftHashTags, setDraftHashTags] = useState(draft)
  const [newHashTag, setNewHashTag] = useState("")
  const [openSnack, setOpenSnack] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  })
  const { vertical, horizontal, open } = openSnack
  const [snackMsg, setSnackMsg] = useState("")
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
  }

  const handleDeleteDraftHashTags = (index: number) => {
    const newDraftHashTags: Array<string> = [...draftHashTags]
    newDraftHashTags.splice(index, 1)
    setDraftHashTags(newDraftHashTags)
  }

  const handleAddDraftHashTags = () => {
    setDraftHashTags([...props.hashTags, newHashTag])
    setNewHashTag("")
  }

  const onUpdateHashTag = async(newState: SnackbarOrigin) => {
    await db
      .collection("users")
      .doc(user.uid)
      .collection("bookmarks")
      .doc(props.objectID)
      .update({ "hashTags": draftHashTags })
      .then(async () => {
        setSnackMsg("Successful update")
        setOpenSnack({ open: true, ...newState })
        props.handleCloseModal()
      })
      .catch(err => {
        setSnackMsg("Update failure:" + err)
        console.log(err)
        setOpenSnack({ open: true, ...newState })
        props.handleCloseModal()
      })

    dispatch(refreshIndex())
  }

  useEffect(() => {
    setDraftHashTags([...props.hashTags])
  }, [props.hashTags, refresh])

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.openModal}
        onClose={props.handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.openModal}>
          <Box sx={style}>
            <div className={styles.edit}>
              <div className={styles.edit__input}>
                <input
                  type="text"
                  placeholder="New hashtag..."
                  value={newHashTag}
                  onChange={e => { setNewHashTag(e.target.value) }}
                />
                <IconButton
                  className={styles.edit__addButton}
                  onClick={() => handleAddDraftHashTags()}
                >
                  <AddIcon className={styles.edit__addButton__icon} />
                </IconButton>
              </div>
              <Stack
                direction="row"
                spacing={1}
                className={styles.edit__chips}
              >
                {
                  draftHashTags.map((tag: string, index: number) => (
                    <Chip
                      key={tag}
                      label={tag}
                      className={styles.edit__chips__chip}
                      onDelete={() => handleDeleteDraftHashTags(index)}
                    />
                  ))
                }
              </Stack>
              <div
                className={styles.edit__btn}
                onClick={() => onUpdateHashTag({ vertical: 'bottom', horizontal: 'center',})}
              >
                Save
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        onClose={() => setOpenSnack({ ...openSnack, open: false })}
        autoHideDuration={2000}
        message={snackMsg}
        key={vertical + horizontal}
      />
    </>
  )
}

export default EditTagModal