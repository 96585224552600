import React from 'react'
import styles from './Footer.module.scss'

const Footer: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <small>© 2022 Tweet Collection</small>
    </footer>
  )
}

export default Footer
