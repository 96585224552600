import React from 'react'
import styles from './Terms.module.scss'

import BasicHeader from  '../components/Header/BasicHeader'
import Footer from '../components/Footer/Footer'

const Terms =() => {

  return (
    <>
      <main className={styles.terms}>
        <BasicHeader />
        <div className={styles.terms_inner}>
          <div className={styles.terms_content}>
            <h2>TweetCollection 利用規約</h2>
            <p>この利用規約（以下「本規約」といいます。）は、TweetCollection（以下、「当社」といいます。）がこのウェブサイト上で提供するサービス（以下「本サービス」といいます。）の利用条件を定めるものです。本サービスを利用する皆さま（以下「ユーザー」といいます。）には、本規約に従って、本サービスをご利用いただきます。</p>
            <section>
              <h3>第1条（適用）</h3>
              <p>1.1. 本規約は、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。</p>
              <p>1.2. 当社は本サービスに関し、本規約のほか、ご利用にあたってのルール等、各種の定め（以下、「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず、本規約の一部を構成するものとします。</p>
              <p>1.3. 本規約の規定が前条の個別規定の規定と矛盾する場合には、個別規定において特段の定めなき限り、個別規定の規定が優先されるものとします。</p>
            </section>
            <section>
              <h3>第2条（利用登録）</h3>
              <p>2.1. 本サービスにおいては、登録希望者が本規約に同意の上、当社の定める方法によって利用登録を申請し、当社がこれを承認することによって、利用登録が完了するものとします。ユーザーとして登録後、本サービスを利用した場合は、会員は利用の都度本規約に同意しているものとみなされます。</p>
              <p>2.2. ユーザーが登録した個人情報は、当社が別途掲載するプライバシーポリシーに基づき、当社にて厳重に管理します。</p>
              <p>2.3. 当社は、利用登録の申請者に以下の事由があると判断した場合、利用登録の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。</p>
              <p>(1) 利用登録の申請に際して虚偽の事項を届け出た場合</p>
              <p>(2) 本規約に違反したことがある者からの申請である場合</p>
              <p>(3) 不正に他人の情報を用いて申請をした場合</p>
              <p>(4) その他、当社が利用登録を相当でないと判断した場合</p>
            </section>
            <section>
              <h3>第3条（ユーザーIDおよびパスワードの管理）</h3>
              <p>3.1. ユーザーは、自己の責任において、本サービスのユーザーIDおよびパスワード等の情報管理責任を負うものとします。</p>
              <p>3.2. ユーザーは、いかなる場合にも、ユーザーIDおよびパスワードを第三者に譲渡または貸与し、もしくは第三者と共用することはできません。当社は、ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には、そのユーザーIDを登録しているユーザー自身による利用とみなします。</p>
              <p>3.3. ユーザーが外部サービスのID等を利用して本サービスに利用登録した場合、そのID等の登録、利用については、外部サービスが規定する各規約の定めに従うものとし、当社はその内容について一切の責任を負いません。</p>
              <p>3.4. ユーザーID及びパスワードの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任はユーザーが負うものとし、当社は一切の責任を負いません。</p>
            </section>
            <section>
              <h3>第4条（外部サービス）</h3>
              <p>4.1. 本サービスは外部サービスと連携してサービスを提供し、ユーザーが許可した場合は、投稿内容その他の情報が、外部サービスに投稿される場合があります。</p>
              <p>4.2. ユーザーは、自らの責任において外部サービスを利用し、外部サービスの利用に当たっては、外部サービスの利用規約等を遵守するものとします。</p>
              <p>4.3. 当社は、外部サービスに投稿された投稿内容について削除等を行う義務を負わず、また、外部サービスの利用に関連してユーザーに発生した損害について一切の責任を負いません。</p>
            </section>
            <section>
              <h3>第5条（利用料金および支払方法）</h3>
              <p>5.1. ユーザーは、本サービスの有料部分の対価として、当社が別途定め、本ウェブサイトに表示する利用料金を、当社が指定する方法により支払うものとします。</p>
              <p>5.2. ユーザーが利用料金の支払を遅滞した場合には、ユーザーは年14.6%の割合による遅延損害金を支払うものとします。</p>
            </section>
            <section>
              <h3>第6条（禁止事項）</h3>
              <p>ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。</p>
              <p>(1) 法令または公序良俗に違反する行為</p>
              <p>(2) 犯罪行為に関連する行為</p>
              <p>(3) 本サービスの内容等、本サービスに含まれる著作権、商標権ほか知的財産権を侵害する行為</p>
              <p>(4) 当社、ほかのユーザー、またはその他第三者のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為</p>
              <p>(5) 本サービスによって得られた情報を商業的に利用する行為</p>
              <p>(6) 当社のサービスの運営を妨害するおそれのある行為</p>
              <p>(7) 不正アクセスをし、またはこれを試みる行為</p>
              <p>(8) 他のユーザーに関する個人情報等を収集または蓄積する行為</p>
              <p>(9) 不正な目的を持って本サービスを利用する行為</p>
              <p>(10) 本サービスの他のユーザーまたはその他の第三者に不利益、損害、不快感を与える行為</p>
              <p>(11) 他のユーザーに成りすます行為</p>
              <p>(12) 当社が許諾しない本サービス上での宣伝、広告、勧誘、または営業行為</p>
              <p>(13) 面識のない異性との出会いを目的とした行為</p>
              <p>(14) 当社のサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為</p>
              <p>(15) その他、当社が不適切と判断する行為</p>
            </section>
            <section>
              <h3>第7条（本サービスの提供の停止等）</h3>
              <p>7.1. 当社は、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。</p>
              <p>(1) 本サービスにかかるシステムの保守点検または更新を行う場合</p>
              <p>(2) 不可抗力（伝染病、天変地異、戦争、テロ、暴動、法令・規則の制定改廃、行政機関による指導および命令等、停電、通信回線及びコンピュータやネットワーク機器等の障害・システムメンテナンス等による中断・遅滞・中止・データの消失、不正アクセス、業務委託先、倉庫物流関連および輸送機関の障害や事故、労働争議、設備の事故等をいいますが、これに限られません）により、各サービスの運営が困難になった場合</p>
              <p>(3) その他、当社が本サービスの提供が困難と判断した場合</p>
              <p>7.2. 当社は、本サービスを最低10日間の予告期間をおいて終了することができます。本サービスの終了は、本サービスに関するお知らせもしくは当社のツイッター等に掲載する方法で告知します。</p>
              <p>7.3. 前三項による本サービスの変更、中断、終了に関連してユーザーに生じた損害について当社は一切責任を負わないものとします。</p>
              <p>7.4. 当社は、本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害についても、一切の責任を負わないものとします。</p>
            </section>
            <section>
              <h3>第8条（利用制限および登録抹消）</h3>
              <p>8.1. 当社は、ユーザーが以下のいずれかに該当する場合には、事前の通知なく、ユーザーに対して、本サービスの全部もしくは一部の利用を制限し、またはユーザーとしての登録を抹消することができるものとします。なお、この場合、当社の判断により、本サービスの利用により既に有効に成立している申込等についても取り消すことがあります。</p>
              <p>(1) 過去に本規約違反などにより利用登録が抹消されていることが判明した場合</p>
              <p>(2) 本規約のいずれかの条項に違反した場合</p>
              <p>(3) 第6条(禁止事項)に該当する行為を行った場合</p>
              <p>(4) 登録事項に虚偽の事実があることが判明した場合</p>
              <p>(5) 料金等の支払債務の不履行があった場合</p>
              <p>(6) 当社からの連絡に対し、一定期間返答がない場合</p>
              <p>(7) 本サービスについて、最終の利用から一定期間利用がない場合</p>
              <p>(8) その他、当社が本サービスの利用を適当でないと判断した場合</p>
              <p>8.2. 当社は、本条に基づき当社が行った行為によりユーザーに生じた損害について、一切の責任を負いません。</p>
            </section>
            <section>
              <h3>第9条（退会）</h3>
              <p>9.1. ユーザーは、本サービスの利用を自己の判断に基づき終了することができます。</p>
              <p>9.2. ユーザーは、当社の定める退会手続きに従い当社にその旨を通知するものとします。</p>
              <p>9.3. 当社は、退会したユーザーの情報を、帳簿等文書の法定保管期間に準じて、一定期間保有するものとします。</p>
            </section>
            <section>
              <h3>第10条（保証の否認および免責事項）</h3>
              <p>10.1. ユーザーは、本サービスを利用するにあたり必要となるインターネットにアクセスのための機器、ソフトウェア、通信手段を各々の責任と費用において適切に準備、設置、操作するものとします。当社はユーザーのアクセス環境に関与せず、これらの準備、操作に関する責任を負いません。</p>
              <p>10.2. 当社は、ユーザーに対して連絡を行う場合に、登録された外部サービスのダイレクトメッセージ等で連絡を行うことがあります。外部サービスアカウントの紛失やダイレクトメッセージの受信設定ができていなかったことにより、当社からの連絡を受信できなかったためにユーザーが不利益を被った場合、当社は責任を負いません。</p>
              <p>10.3. 本サービス上においてユーザー自身が自ら開示した、テキスト、コード、スニペット、音声、動画、画像及び画像に付随する情報を含む全ての情報に関する責任は、当該情報を開示したユーザーにあります。当社はユーザーが本サービスにおいて開示した情報の内容について、一切の責任を負いません。</p>
              <p>10.4. ユーザーが他人の名誉を毀損した場合、プライバシー権を侵害した場合、著作権法に違反する行為を行った場合、又はその他の方法により他人の権利を侵害した場合、当該ユーザーは自身の責任と費用において解決するものとし、当社は一切の責任を負いません。</p>
              <p>10.5. 当社は、本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。</p>
              <p>10.6. 当社は、本サービスに起因してユーザーに生じたあらゆる損害について、当社の故意又は重過失による場合を除き、一切の責任を負いません。ただし、本サービスに関する当社とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合、この免責規定は適用されません。</p>
              <p>10.7. 前項ただし書に定める場合であっても、当社は、当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当社またはユーザーが損害発生につき予見し、または予見し得た場合を含みます。）について一切の責任を負いません。また、当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害の賠償は、ユーザーから当該損害が発生した月に受領した利用料の額を上限とします。</p>
              <p>10.8. 当社は、本サービスに関して、ユーザーと他のユーザーまたは第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。</p>
              <p>10.9. ユーザーは自らの責任において本サービスを利用するものとし、本サービスの利用に関連してなされた一切の行為及びその結果について、ユーザーが責任を負うものとします。</p>
            </section>
            <section>
              <h3>第11条（サービス内容の変更等）</h3>
              <p>当社は、ユーザーへの事前の告知をもって、本サービスの内容を変更、追加または廃止することがあり、ユーザーはこれを承諾するものとします。</p>
            </section>
            <section>
              <h3>第12条（利用規約の変更）</h3>
              <p>12.1. 当社は以下の場合には、ユーザーの個別の同意を要せず、当社の判断により適時本規約を変更することができるものとします。</p>
              <p>(1) 本規約の変更がユーザーの一般の利益に適合するとき。</p>
              <p>(2) 本規約の変更が本サービス利用契約の目的に反せず、かつ、変更の必要性、変更後の内容の相当性その他の変更に係る事情に照らして合理的なものであるとき。</p>
              <p>12.2. 当社はユーザーに対し、前項による本規約の変更にあたり、事前に、本規約を変更する旨及び変更後の本規約の内容並びにその効力発生時期を通知します。</p>
              <p>12.3. なお、ユーザーは、変更後の本規約の内容を承諾しない場合、その選択に従って、退会することができるものとします。</p>
            </section>
            <section>
              <h3>第13条（個人情報の取扱い）</h3>
              <p>当社は、本サービスの利用によって取得する個人情報については、当社「プライバシーポリシー」に従い適切に取り扱うものとします。</p>
            </section>
            <section>
              <h3>第14条（通知または連絡）</h3>
              <p>ユーザーと当社との間の通知または連絡は、当社の定める方法によって行うものとします。当社は、ユーザーから、当社が別途定める方式に従った変更届け出がない限り、現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い、これらは、発信時にユーザーへ到達したものとみなします。</p>
            </section>
            <section>
              <h3>第15条（権利義務の譲渡）</h3>
              <p>15.1. 当社は、本サービスに関する営業を第三者に譲渡する場合、最低10日間の予告期間をおいて、事前に本サービスに関するお知らせもしくは当社のツイッターアカウント等で告知の上、本規約に基づくすべての当社の権利及び義務を、承継、売却、合併、その他の方法で譲渡することができるものとします。当社はこの場合において、当社がかかる権利及び義務を譲り受ける者にユーザーの情報及び投稿内容等を、当該譲渡先に開示をすることができるものとします。</p>
              <p>15.2. ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。</p>
            </section>
            <section>
              <h3>第16条（本サービス提供に関わる知的財産権）</h3>
              <p>16.1. 本サービス及び本サービスを提供するために当社が使用するサーバー等を構成するすべてのプログラム、ソフトウェア、商標、商号又はこれらに付随する知的財産権及びその他の権利は、特段の表示なき限り、当社又は当社に使用を許諾した正当な権限を有する第三者に帰属します。</p>
              <p>16.2. ユーザーは、当社又は第三者の知的財産権を、事前かつ明示の許諾なく複製、出版、放送、公衆送信、その他方法を問わず、私的使用の範囲を越えて使用することはできません。</p>
            </section>
            <section>
              <h3>第17条（分離可能性）</h3>
              <p>本規約のいずれかの条項又は一部が、法令等により無効又は執行不能と判断された場合は、当該条項又は一部のみが無効となり、本規約のその他の条項は引き続き完全に効力を有するものとします。</p>
            </section>
            <section>
              <h3>第18条（準拠法・裁判管轄）</h3>
              <p>18.1. 本規約の解釈にあたっては、日本法を準拠法とします。</p>
              <p>18.2. 本規約により解決できない問題が生じた場合には、ユーザーと当社は、双方誠意をもって話し合い、これを解決するものとします。</p>
              <p>18.3. 本規約から生じる又は本規約に関連する裁判手続きは、訴額に応じて福岡地方裁判所又は福岡簡易裁判所を第1審の専属的合意管轄裁判所とします。</p>
            </section>
            <p className={styles.terms_text_right}>以上</p>
            <p>最終更新日 : 2022年9月10日</p>
          </div>
        </div>

        <Footer />
      </main>
    </>
  )
}

export default Terms
