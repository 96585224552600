import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Auth.module.scss'
import firebase from 'firebase/app'
import { auth, db, provider } from '../firebase'
import Box from '@mui/material/Box'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import TwitterIcon from '@mui/icons-material/Twitter';
import { TwAccount, InitialUser } from '../types/user'
import Footer from '../components/Footer/Footer'


const theme = createTheme()

const Auth: React.FC = () => {
  const signInTwitter = async(event: any) => {
    event.preventDefault()
    let uid: string | undefined

    await auth
      .signInWithPopup(provider)
      .then(async result => {
        if (result.credential) {
          const credential: any = result.credential
          uid = result.user?.uid // auth uid
          const twAccountInfo: TwAccount = {
            userId: JSON.parse(JSON.stringify(result.additionalUserInfo?.profile)).id_str, // Twitter ID Number
            screenName: result.additionalUserInfo?.username, // Twitter ID
            accessToken: credential.accessToken,
            secret: credential.secret,
          }
          const initialUser: InitialUser = {
            uid: uid,
            twAccountInfo: twAccountInfo,
            securedApiKey: null,
          }

          const userDoc = await db
            .collection("users")
            .doc(uid)
            .get()

          const timestamps = ["createdAt", "updatedAt", "loginedAt", "isUpdating"]

          if(!userDoc.exists) {
            await db.collection("users").doc(uid).set(initialUser)

            const batch = db.batch()
            timestamps.map((timestamp: string) => {
              const timestampsRef = db.collection("users").doc(uid).collection("timestamps").doc(timestamp)
              if(timestamp === "isUpdating") {
                timestampsRef.set({
                  isUpdating: true,
                })
              } else {
                batch.set(timestampsRef, {
                  timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                })
              }
              return true
            })
            await batch.commit()
          } else {
            const loginedAtRef = db.collection("users").doc(uid).collection("timestamps").doc("loginedAt")
            await loginedAtRef.update({
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            })
            const timestampsRef = db.collection("users").doc(uid).collection("timestamps").doc("isUpdating")
            await timestampsRef.update({
              isUpdating: true,
            })
          }
        } else {
          alert("CREDANTIAL ERROR")
        }
      })
      .catch(err => {
        auth.signOut()
        alert("err:" + err.message)
      })
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={styles.auth}>
        <div className={styles.auth__container}>
          <h2 className={styles.auth__txt__catch}>Log in or sign up to continue<br/>managing your favorite tweets</h2>
          <Box
            component="form"
            className={styles.auth__form}
          >
            <p className={styles.auth__txt__offer}>By signing up, you agree to our <Link to="/terms" className={styles.auth__txt__offer_link}>Terms</Link> and <Link to="/privacy" className={styles.auth__txt__offer_link}>Privacy Policy</Link>.</p>
            <button
              className={styles.auth__form__button}
              type="button"
              onClick={signInTwitter}
            >
              <TwitterIcon/><span>Continue with Twitter</span>
            </button>
          </Box>
        </div>
      </div>

      <Footer />
    </ThemeProvider>
  )
}

export default Auth