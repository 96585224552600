import styles from './CustomTagRefinementList.module.scss';
import { connectRefinementList, Highlight } from 'react-instantsearch-dom';

const CustomTagRefinementList = connectRefinementList((
  {
    items,
    isFromSearch,
    refine,
    createURL,
    searchForItems,
  }
) => {
  return (
    <ul className={styles.hashtag_list}>
      <li className={styles.hashtag_list__search}>
        <input
          type="search"
          placeholder="ハッシュタグ検索"
          onChange={event => searchForItems(event.currentTarget.value)}
        />
      </li>
      {items.map(item => (
        <li className={styles.hashtag_item} key={item.label}>
          <a
            href={createURL(item.value)}
            className={`${styles.hashtag_item__link} ${item.isRefined && styles.hashtag_item__link_crr}` }
            onClick={event => {
              event.preventDefault();
              refine(item.value);
            }}
          >
            {isFromSearch ? (
              <Highlight attribute="label" hit={item} />
            ) : (
              item.label
            )}{' '}
            ({item.count})
          </a>
        </li>
      ))}
    </ul>
  )
});

export default CustomTagRefinementList;