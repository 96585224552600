import React, { useEffect, useState } from 'react'
import styles from './CustomInfiniteHits.module.scss'
import { useSelector } from 'react-redux'
import { selectRefresh } from '../features/refreshIndexSlice'
import { connectInfiniteHits } from 'react-instantsearch-dom'
import CustomHit from './CustomHit'

import { Bookmark } from "../types/bookmark"

const CustomInfiniteHits = connectInfiniteHits(({
  hits,
  // hasPrevious,
  // hasMore,
  // refinePrevious,
  // refineNext,
}) => {
  const [hitsItems, setHitsItems] = useState(hits)
  const refresh = useSelector(selectRefresh)

  useEffect(() => {
    setHitsItems(hits)
  }, [hits, refresh])

  return (
    <>
      <ul className={styles.bookmarks}>
        {refresh || hitsItems.map((hit: Bookmark) => (
          <CustomHit hit={hit} key={hit.objectID} />
        ))}
      </ul>
    </>
  )
})

export default CustomInfiniteHits