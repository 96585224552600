import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectUser } from '../../features/userSlice'
import { auth, db } from '../../firebase'
import styles from './SearchDrawer.module.scss'

import { IconButton, ListItemIcon, Menu, MenuItem, Snackbar, SnackbarOrigin, Tooltip } from '@mui/material'
import { Avatar } from '@material-ui/core'

import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import CloseIcon from '@mui/icons-material/Close'
import Logout from '@mui/icons-material/Logout'
import MenuOpenTwoToneIcon from '@mui/icons-material/MenuOpenTwoTone'
import TuneTwoToneIcon from '@mui/icons-material/TuneTwoTone'

import CustomSearchBox from '../CustomSearchBox'
import CustomTagRefinementList from '../CustomTagRefinementList'

interface PROPS {
  currentRefinement: string
  isSearchStalled: boolean
  refine: Function
}

interface SnackbarState extends SnackbarOrigin {
  open: boolean
}

const SearchDrawer: React.FC<PROPS> = ({currentRefinement, isSearchStalled, refine }) => {
  const [isOpenSnack, setIsOpenSnack] = useState<SnackbarState>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  })
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { vertical, horizontal, open } = isOpenSnack
  const [ snackMsg, setSnackMsg ] = useState("")
  const [ isOpenSearch, setIsOpenSearch] = useState(false)
  const user = useSelector(selectUser)

  const isOpenMenu = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const onUpdateBookmarks = (newState: SnackbarOrigin) => async() => {
    console.log("update")
    setSnackMsg("Updating...")
    setIsOpenSnack({ open: true, ...newState })
    const uid = user.uid
    const isUpdatingRef = db.collection("users").doc(uid).collection("timestamps").doc("isUpdating")
    await isUpdatingRef
      .update({
        isUpdating: true,
      })
  }

  return (
    <>
      <div
        className={isOpenSearch ? styles.search_drawer__overlay : ''}
        onClick={()=> setIsOpenSearch(false)}
      />
      <aside className={`${styles.search_drawer} ${isOpenSearch ? styles.search_drawer_open : ''}`}>
        <div className={styles.search_drawer__userCard}>
          <div className={styles.search_drawer__userCard__meta}>
            <div className={styles.search_drawer__userCard__meta__user}>
              <Avatar src={user.photoUrl} />
              <p className={styles.search_drawer__userCard__meta__name}>{user.displayName}</p>
            </div>
            <div>
              <Tooltip
                className={styles.search_drawer__userCard__meta__iconButton}
                title="update"
              >
                <IconButton
                  onClick={onUpdateBookmarks({
                    vertical: 'bottom',
                    horizontal: 'center',
                  })}
                >
                  <AutorenewRoundedIcon className={styles.search_drawer__userCard__meta__iconButton__icon} />
                </IconButton>
              </Tooltip>

              <Tooltip
                className={styles.search_drawer__userCard__meta__iconButton}
                title="Menu"
              >
                <IconButton onClick={(e) => handleClick(e)}>
                  <MenuOpenTwoToneIcon className={styles.search_drawer__userCard__meta__iconButton__icon} />
                </IconButton>
              </Tooltip>

              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={isOpenMenu}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem onClick={() => auth.signOut()}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
                <MenuItem>
                  <Link to="/terms" className={styles.search_drawer__link}>
                    Terms of servise
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/privacy" className={styles.search_drawer__link}>
                    Privacy policy
                  </Link>
                </MenuItem>
                <MenuItem>
                  <a href="google.com" target="_blank" className={styles.search_drawer__link}>
                    Contact
                  </a>
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>

        <div className={styles.search_drawer__tags}>
          <div className={styles.search_drawer__tags__title}>
            <h3>
              <span className={styles.search_drawer__tags__title__icon}>
                <img
                  src={`${process.env.PUBLIC_URL}/icon_hashtag.png`}
                  alt="hashtag icon"
                />
              </span>
              Hashtag
            </h3>
            <IconButton
              className={styles.search_drawer__btn_close}
              onClick={() => setIsOpenSearch(false)}
            >
              <CloseIcon className={styles.search_drawer__btn_close_icon} />
            </IconButton>
          </div>

          <div className={styles.search_drawer__tags__container}>
            <CustomTagRefinementList
              attribute="hashTags"
              operator="and"
              limit={10}
              showMore={true}
              searchable
            />
          </div>
        </div>
      </aside>

      <div className={styles.search_drawer__nav}>
        <CustomSearchBox
          currentRefinement={currentRefinement}
          isSearchStalled={isSearchStalled}
          refine={refine}
        />
        <div
          className={styles.search_drawer__btn_filter}
          onClick={() => setIsOpenSearch(true)}
        >
          <TuneTwoToneIcon />
          <p>絞り込む</p>
        </div>
      </div>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={() => setIsOpenSnack({ ...isOpenSnack, open: false })}
        autoHideDuration={2000}
        message={snackMsg}
        key={vertical + horizontal}
      />
    </>
  )
}

export default SearchDrawer