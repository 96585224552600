import React, { useState } from 'react'
import styles from './BasicHeader.module.scss'

import { IconButton } from '@mui/material'
import MenuOpenTwoToneIcon from '@mui/icons-material/MenuOpenTwoTone'

import BasicGlobalDrawer, { toggleGlobalDrawer } from '../Drawer/BasicGlobalDrawer'

const BasicHeader: React.FC = () => {
  const [ isOpenGlobalDrawer, setIsOpenGlobalDrawer] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  return (
    <>
      <header className={styles.header}>
        <div className={styles.header__inner}>
          <div className={styles.header__col_l}>
            <h1>Tweet<span>Stock</span></h1>
          </div>

          <div className={styles.header__col_r}>
            <IconButton onClick={toggleGlobalDrawer(isOpenGlobalDrawer, setIsOpenGlobalDrawer, true)}>
              <MenuOpenTwoToneIcon/>
            </IconButton>
          </div>
        </div>
      </header>
      <BasicGlobalDrawer
        isOpenDrawer={isOpenGlobalDrawer}
        setIsOpenDrawer={setIsOpenGlobalDrawer}
      />
    </>
  )
}

export default BasicHeader