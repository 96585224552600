import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import styles from './App.module.scss'
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch } from 'react-instantsearch-dom'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser, login, logout } from './features/userSlice'
import { selectRefresh, completeRefresh } from './features/refreshIndexSlice'
import { auth, db } from "./firebase"
import Feed from './pages/Feed'
import Auth from './pages/Auth'
import Terms from './pages/Terms'
import Privacy from './pages/Privacy'

const getSearchClient = (apiKey: string) => {
  const client = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID || '0', apiKey)
  return client
}

const Search: React.FC<any> = ({ apiKey }: {apiKey: string}) => {
  const refresh = useSelector(selectRefresh)
  const searchClient = getSearchClient(apiKey)
  const dispatch = useDispatch()
  // console.log("App.tsx:init")

  useEffect(() => {
    // console.log("App.tsx:effect before if")
    if (refresh) {
      (async () => {
        searchClient.clearCache()
        // console.log("App.tsx:effect before settimeout")

        await new Promise(() => setTimeout(() => {
          // console.log("App.tsx:effect in settimeout")
          dispatch(completeRefresh())
        }, 5000))
        clearTimeout()
      })()

      // console.log("App.tsx:effect after settimeout")
    }

    return () => {
      // クリーンアップ処理
      clearTimeout()
    }
  }, [refresh, dispatch, searchClient])

  // console.log("App.tsx: after effect")

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName="bookmarks"
      refresh={refresh}
    >
      <div className={styles.app}>
        <Feed />
      </div>
    </InstantSearch>
  )
}

const App: React.FC = () => {
  const user = useSelector(selectUser)
  const dispatch = useDispatch()

  useEffect(() => {
    const unSub = auth.onAuthStateChanged(async(authUser)=>{
      if(authUser) {

        let apiKey
        const docRef = db.collection("users").doc(authUser.uid)
        const docSnap = await docRef.get()
        if (docSnap.exists) {
          apiKey = docSnap.data()?.securedApiKey
        }

        dispatch(login({
          uid: authUser.uid,
          photoUrl: authUser.photoURL,
          displayName: authUser.displayName,
          apiKey: apiKey
        }))
      } else {
        dispatch(logout())
      }
    })

    return () => {
      unSub()
    }
  }, [dispatch])

  return (
    <BrowserRouter>
      <Routes>
        { user.uid ? (
          <Route path="/" element={<Search apiKey={user.apiKey} />} />
        ): (
          <Route path="/" element={<Auth />} />
        )}
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
