import React from 'react'
import { Configure, connectSearchBox } from 'react-instantsearch-dom'

import styles from './Feed.module.scss'

import CustomInfiniteHits from '../components/CustomInfiniteHits'
import Header from  '../components/Header/Header'
import Progress from '../components/Progress/Progress'
import SearchDrawer from '../components/Drawer/SearchDrawer'

import { useSelector } from 'react-redux'
import { selectRefresh } from '../features/refreshIndexSlice'

const Feed = connectSearchBox(({ currentRefinement, isSearchStalled, refine }) => {
  const refresh = useSelector(selectRefresh)

  return (
    <div className={styles.feed}>
      {/* main */}
      <main className={styles.feed__main}>
        <form noValidate action="" role="search">
          <Header
            currentRefinement={currentRefinement}
            isSearchStalled={isSearchStalled}
            refine={refine}
          />

          <Configure />

          <div className={styles.bookmark__container}>
            {refresh && <Progress />}
            {refresh || <CustomInfiniteHits />}
          </div>
        </form>
      </main>
      {/* end main */}

      {/* sidenav */}
      <SearchDrawer
        currentRefinement={currentRefinement}
        isSearchStalled={isSearchStalled}
        refine={refine}
      />
      {/* end sidenav */}
    </div>
  )
})

export default Feed
